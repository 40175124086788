import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Apis } from "../../config";
const CKEditorField = ({ field, form }) => {

  function uploadAdapter(loader) {
    var token = sessionStorage.getItem("userToken");
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("ckFile", file);
            fetch(`${Apis.UploadCkeditor}`, {
              method: "POST",
              body: body,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: res.image_url });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

    const { name, value } = field;
  
    const handleEditorChange = (event, editor) => {
      const data = editor.getData();
      form.setFieldValue(name, data);
    };
  
    return (
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [uploadPlugin],
        }}
        data={value}
        onChange={handleEditorChange}
      />
    );
  };

  export default CKEditorField;