import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { Formik } from "formik";
import { GetProductCategory, Alerts, GetProducts } from "../../../helpers";
import MediaLibraryPopup from "../../../components/cmp/MediaLibraryPopup";
import { FiEdit } from "react-icons/fi";
import MUIDataTable from "mui-datatables";
import { FaTimes } from "react-icons/fa";
import { format } from 'date-fns';
import ConfirmationDialog from '../../../components/cmp/ConfirmationDialog';
import { FaImages } from "react-icons/fa";

const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Categories",
        link: "/app/product/categories",
    },
    {
        id: 3,
        label: "Create New",
        link: "/app/product/categories/create",
    },
];
const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: false
};

const columns = [
    {
        name: 'SL'
    },
    {
        name: 'Product Image',
        sortable: true,
    },
    {
        name: 'Create At',
        sortable: true,
    },
    {
        name: 'Actions',
        sortable: false,
    },
];

const ProductSlider = () => {
    const { product_id } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [mediaData, setMediaData] = useState([]);
    const [showMedia, setShowMedia] = useState(false);
    const [product_thumb, setProductThumb] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [delId, setDelId] = useState(null);

    const getProductMedia = async (pro_id) => {
        let resp = await GetProducts.getProductGallery(pro_id);
        if (resp && resp.status) {
            setMediaData(resp.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        getProductMedia(product_id);
    }, [product_id]);

    const handleSelectImage = (image) => {
        setProductThumb(image);
        setShowMedia(false);
    }

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const data = {
            media_id: values.product_image
        }
        let resp = await GetProducts.createProductGallery(data, product_id);
        if (resp && resp.status) {
            Alerts.alertMessage(resp.message, "top-right", "success");
            getProductMedia(product_id);
        } else {
            Alerts.alertMessage(resp.message, "top-right", "error");
        }
        setProductThumb('');
        resetForm();
        setSubmitting(false);
    };

    const showDialogBox = (id) => {
        setDelId(id);
        setShowDialog(true);
    }

    const deleteRecord = async (id) => {
        let resp = await GetProducts.deleteProductGallery(id);
        if (resp && resp.status) {
            Alerts.alertMessage(resp.message, 'top-right', 'success');
            getProductMedia(product_id);
        } else {
            Alerts.alertMessage(resp.message, 'top-right', 'error');
        }
        setDelId(null);
        setShowDialog(false);
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Product
                        </h2>
                        <Link to="/app/products" className="btn btn-success">
                            <BiCategory /> Products
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Formik
                                initialValues={{
                                    product_image: '',
                                }}
                                validate={(values) => {
                                    const errors = {};
                                    if (!values.product_image) {
                                        errors.product_image = "Product image is required";
                                    }

                                    return errors;
                                }}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">


                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Product Thumbnail</label>
                                                    <div className="dropzone-box">
                                                        {
                                                            product_thumb !== '' && <img src={product_thumb.path} alt="product_thumbnail" />
                                                        }
                                                        {
                                                            product_thumb !== '' ? <button type="button" className="floatBtn" onClick={() => setShowMedia(true)}><FiEdit size={15} /></button> : <>
                                                                <p>Drop files to upload</p>
                                                                <p>or</p>
                                                                <button type="button" onClick={() => setShowMedia(true)} className="choose-btn">Choose file</button>
                                                            </>
                                                        }
                                                    </div>


                                                    {errors.product_image && touched.product_image && (
                                                        <span className="help-error">
                                                            {errors.product_image}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? "Uploading..." : "Upload Now"}
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        {
                                            showMedia && <MediaLibraryPopup show={showMedia} setShowMedia={setShowMedia} handleSelectImage={handleSelectImage} setFieldValue={setFieldValue} fieldName="product_image" />
                                        }
                                    </form>

                                )}
                            </Formik>
                            <br />
                            {
                                isLoading ? <div className='loader'>
                                    <img src='/images/loader.gif' alt='loader' />
                                </div> : <>
                                    <MUIDataTable
                                        title="Product Gallery"
                                        data={mediaData.map((item, i) => {

                                            return [
                                                ++i,
                                                <img src={item.media_file.path} alt="thumbnail" width="80px" />,
                                                format(item.createdAt, 'dd/MM/yyyy'),
                                                <div className={`actions`}>
                                                    <button onClick={() => showDialogBox(item.id)} className="btn btn-danger"><FaTimes size={14} /></button>
                                                </div>
                                            ]
                                        })}
                                        columns={columns}
                                        options={options}
                                    />
                                    {showDialog ? <ConfirmationDialog delId={delId} setShowDialog={setShowDialog} deleteRecord={deleteRecord} show={showDialog} message="Are you sure you want to delete this record." /> : null}
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductSlider;
