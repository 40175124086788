import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik, Field } from "formik";
import { Alerts, GetPost } from "../../../helpers";
import CKEditorField from '../../../components/cmp/CKEditorField';
import { Apis } from "../../../config";
import { format } from "date-fns";
import MediaLibraryPopup from "../../../components/cmp/MediaLibraryPopup";
import { FiEdit } from "react-icons/fi";
const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Categories",
    link: "/app/post/categories",
  },
  {
    id: 3,
    label: "Create New",
    link: "/app/post/categories/create",
  },
];



const EditPost = () => {
  const history = useHistory();
  const {id} = useParams()
  const [isLoading, setLoading] = useState(true);
  const [postData, setPostData] = useState("");
  const [categories, setCategories] = useState("");

  const [showMedia, setShowMedia] = useState(false);
  const [imageFile, setImageFile] = useState('');
  const [imageFile2, setImageFile2] = useState('');
  const [fileUploadName , setFileUploadName] = useState('');

  const handleSubmit = async (values) => {
     
     const data = {
      title: values.title,
      excerpt: values.excerpt,
      description: values.description,
      thumbnail: values.thumbnail,
      featured_image: values.featured_image,
      category_id: values.category_id,
      publication_date: values.publish_date
     }
 
    let resp = await GetPost.updatePost(data, id);
    if (resp && resp.status) {
      Alerts.alertMessage(resp.message, "top-right", "success");
      history.push("/app/posts");
    } else {
      Alerts.alertMessage(resp.message, "top-right", "error");
    }
  };


  const getData = async () =>{
    let resp = await GetPost.getAllPostCategories();
    if(resp && resp.status){
      setCategories(resp.data);
    }
 }

 const getPostData = async (id) =>{
    let resp = await GetPost.getSinglePost(id);
    if(resp && resp.status){
        setPostData(resp.data);
        setImageFile(resp.data.post_thumbnail);
        setImageFile2(resp.data.post_featured_image);
        setLoading(false);
    }

 }
 useEffect(()=>{
   getData();
   getPostData(id)
 },[id])

 const handleSelectImage = (image) => {
  setImageFile(image);
  setShowMedia(false);
}

 const handleSelectImage2 = (image) => {
  setImageFile2(image);
  setShowMedia(false);
}

const handleShowMedia = (fieldName) =>{
  setShowMedia(true);
  setFileUploadName(fieldName);
}

  function uploadAdapter(loader) {
    var token = sessionStorage.getItem("userToken");
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("ckFile", file);
            fetch(`${Apis.UploadCkeditor}`, {
              method: "POST",
              body: body,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: res.image_url });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <div className="container-fluid">
          <div className="page-title">
            <h2>
              <BiCategory /> Edit Post
            </h2>
            <Link to="/app/posts" className="btn btn-success">
              <BiCategory /> Post
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
                {
                       isLoading ? <div className='loader'>
                        <img src='/images/loader.gif' alt='loader'/>
                      </div> :    <Formik
                enableReinitialize={true}
                initialValues={{
                  title: postData.title,
                  excerpt: postData.excerpt,
                  description: postData.description,
                  thumbnail: postData.thumbnail,
                  featured_image: postData.featured_image,
                  category_id: postData.category_id,
                  publish_date: postData.publication_date
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.title) {
                    errors.title = "Post title is required";
                  }

                  if (!values.excerpt) {
                    errors.excerpt = "Post excerpt is required";
                  }

                  if (!values.description) {
                    errors.description = "Post description is required";
                  }

                  if (!values.thumbnail) {
                    errors.thumbnail = "Thumbnail is required";
                  }
                  if (!values.featured_image) {
                    errors.featured_image = "Featured image is required";
                  }
                  if (!values.category_id) {
                    errors.category_id = "Category is required";
                  }
                  if (!values.publish_date) {
                    errors.publish_date = "Publish date is required";
                  }

                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue, 
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-9">
                      <div className="form-group">
                      <label>Post Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {errors.title && touched.title && (
                        <span className="help-error">{errors.title}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Excerpt</label>
                      <textarea className="form-control" name="excerpt" onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.excerpt} />
                      {errors.excerpt && touched.excerpt && (
                        <span className="help-error">{errors.excerpt}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Description</label>
                      <Field
                        name="description"
                        render={({ field, form }) => (
                          <CKEditorField field={field} form={form} uploadPlugin={uploadPlugin} />
                        )}
                      />
                      {errors.description && touched.description && (
                        <span className="help-error">{errors.description}</span>
                      )}
                    </div>
                  

                      </div>
                      <div className="col-md-3">
                      <div className="form-group">
                        <label>Thumbnail</label>
                        <div className="dropzone-box">
                            {
                              imageFile !== '' && <img src={imageFile.path} alt="product_thumbnail" className="mx-220"/>
                            }
                            {
                              imageFile !== '' ? <button type="button" className="floatBtn" onClick={() => handleShowMedia('thumbnail')}><FiEdit size={15} /></button> : <>
                                <p>Drop files to upload</p>
                                <p>or</p>
                                <button type="button" onClick={() => handleShowMedia('thumbnail')} className="choose-btn">Choose file</button>
                              </>
                            }
                          </div>
                          {errors.thumbnail && touched.thumbnail && (
                            <span className="help-error text-center">{errors.thumbnail}</span>
                          )}

                      </div>
                      <div className="form-group">
                        <label>Featured Image</label>
                        <div className="dropzone-box">
                            {
                              imageFile2 !== '' && <img src={imageFile2.path} alt="product_thumbnail" className="mx-220"/>
                            }
                            {
                              imageFile2 !== '' ? <button type="button" className="floatBtn" onClick={() => handleShowMedia('featured_image')}><FiEdit size={15} /></button> : <>
                                <p>Drop files to upload</p>
                                <p>or</p>
                                <button type="button" onClick={() => handleShowMedia('featured_image')} className="choose-btn">Choose file</button>
                              </>
                            }
                          </div>
                          {errors.featured_image && touched.featured_image && (
                            <span className="help-error text-center">{errors.featured_image}</span>
                          )}
                      </div>
                      <div className="form-group">
                        <label>Category</label>
                        <select className="form-control"
                          name="category_id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.category_id}>
                          <option value={''}>--Select Category--</option>
                          {
                            categories.length > 0 && categories.map(item=>{
                             return <option value={item.id}>{item.title}</option>
                            })
                          }
                        </select>
                        {errors.category_id && touched.category_id && (
                          <span className="help-error">{errors.category_id}</span>
                        )}
                      </div>
                      <div className="form-group">

                        <label>Publish Date - {format(values.publish_date, "dd-MM-yyyy") }</label>
                        <input type="date" className="form-control" name="publish_date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.publish_date} />
                        {errors.publish_date && touched.publish_date && (
                          <span className="help-error">{errors.publish_date}</span>
                        )}
                      </div>
                      {
                      showMedia && <MediaLibraryPopup show={showMedia} setShowMedia={setShowMedia} handleSelectImage={fileUploadName === 'thumbnail' ? handleSelectImage : handleSelectImage2} setFieldValue={setFieldValue} fieldName={fileUploadName} />
                    }
                      <div className="text-center">
                      <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={isSubmitting}
                      style={{marginTop:'30px', width:'100%', maxWidth:'150px'}}
                    >
                      {isSubmitting ? "Updating..." : "Update Now"}
                    </button>
                      </div>
                    
           
                      </div>
                    </div>
                
   
                  </form>
                )}
              </Formik>
                }
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPost;
