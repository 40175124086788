import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = sessionStorage.getItem('userToken');
const getAllUser = async () => {
  try {
    let result = await api.get(Apis.GetAllUsers, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const exportedObject = {
    getAllUser
};
export default exportedObject;