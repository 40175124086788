import React, {useState, useEffect} from 'react'
import Breadcrumb from '../../../components/partials/Breadcrumb';
import { BiCategory } from "react-icons/bi";
import { Alerts, GetProductCategory, GetProducts } from '../../../helpers';
import MUIDataTable from "mui-datatables";
import { Link } from  'react-router-dom';
import { FaEdit, FaTimes, FaPlus, FaQuestion, FaImages   } from "react-icons/fa";
import ConfirmationDialog from '../../../components/cmp/ConfirmationDialog';
import parse from 'html-react-parser';
const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Products",
    link: "/app/products",
  },
];

const Products = () => {
  const [isLoading, setLoading] = useState(true);
  const [arrData, setData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [delId, setDelId] = useState(null);
  const getData = async () =>{
     let resp = await GetProducts.getAllProducts();
     if(resp && resp.status){
         setData(resp.data);
         setLoading(false);
     }
  }

  useEffect(()=>{
    getData();
  },[])
  
  const options = {
    filter: false,
    download:false,
    print:false,
    selectableRows: false
  };

  const columns = [
    {
      name: 'SL'
    },
    {
        name: 'SKU',
        sortable: true,
      },
    {
      name: 'Title',
      sortable: true,
    },
    {
      name: 'Category',
      sortable: true,
    },
    {
      name: 'Thumbnail',
    },
    {
        name: 'Price',
        sortable: true,
      },
     
    {
      name: 'Status',
      sortable: true,
    },
    {
      name: 'Actions',
      sortable: false,
    },
  ];
  
const deleteRecord = async (id) =>{
   let resp = await GetProductCategory.deleteCategory(id); 
   if(resp && resp.status){
    Alerts.alertMessage(resp.message, 'top-right', 'success');
    getData();
   }else{
    Alerts.alertMessage(resp.message, 'top-right', 'error');
   }
   setDelId(null);
   setShowDialog(false);
}
 
const showDialogBox = (id) =>{
  setDelId(id);
  setShowDialog(true);
}
  return (
    <>
    <Breadcrumb breadcrumbs={breadcrumbs} />
       <div className="innerPageWrapper">
       <div className='container-fluid'>
        <div className='page-title'>
          <h2><BiCategory/> Products</h2>
          <Link to="/app/product/create" className="btn btn-success"><FaPlus/> Add New</Link>
        </div>
             {
              isLoading ? <div className='loader'>
                <img src='/images/loader.gif' alt='loader'/>
              </div> : <>
                 <MUIDataTable
                            title="Products"
                            data={arrData.map((item, i) => {

                                return [
                                    ++i,
                                    item.sku,
                                    item.title,
                                    // parse(item.short_description),

                                   item.categories.length > 0 && item.categories.map(el=>{
                                    return `${el.title},`;
                                   }),
                                    <img src={item.product_thumbnail.path} alt="thumbnail" width="80px"/>,
                                    <div><del>{item.regular_price}</del> <br/> {item.sale_price}</div>,
                                    <div className={`badge ${item.status ? "bg-success" : "bg-danger"}`}>{item.status ? 'Active' : 'Draft'}</div>,
                                    <div className={`actions`} style={{width:"150px"}}>
                                      <Link to={`/app/product/edit/${item.id}`} className="btn btn-info"><FaEdit size={14}/></Link> | <button onClick={()=>showDialogBox(item.id)} className="btn btn-danger"><FaTimes size={14}/></button> |  <Link to={`/app/product/media/${item.id}`} className="btn btn-secondary"><FaImages size={14}/></Link>
                                    </div>
                                ]
                            })}
                            columns={columns}
                            options={options}
                        />
                      {showDialog ?  <ConfirmationDialog delId={delId} setShowDialog={setShowDialog} deleteRecord={deleteRecord} show={showDialog} message="Are you sure you want to delete this record."/> : null} 
              </>
             }
          </div>
      </div>
      </>
  )
}

export default Products;