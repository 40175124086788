import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Protected from './protected/Protected'
import Layout from './components/Layouts/Layout';
import NotFound from './pages/NotFound';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from './pages/Login';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
                <Switch>
                    <Route path="/" exact render={() => <Redirect to="/app/dashboard"/>}/>
                    <Route path="/app" exact render={() => <Redirect to="/app/dashboard"/>}/>
                    <Protected path="/app" component={Layout}/>
                    <Route path="/login" exact component={Login}/>
                    <Route component={NotFound} /> 
                </Switch>
          </BrowserRouter>
    </div>
  );
}

export default App;
