import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = sessionStorage.getItem('userToken');

const getAllProducts = async () => {
    try {
      let result = await api.get(Apis.GetAllProducts, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

const createProduct = async (data) => {
    try {
      let result = await api.post(Apis.CreateProduct, data, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const updateProduct = async (data, id) => {
    try {
      let result = await api.put(Apis.UpdateProduct+id, data, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };


  const getSingleProducts = async (id) => {
    try {
      let result = await api.get(Apis.GetSingleProduct+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const createProductGallery = async (data,id) => {
    try {
      let result = await api.post(Apis.CreateProductGallery+id, data, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  const getProductGallery = async (id) => {
    try {
      let result = await api.get(Apis.GetProductGallery+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const deleteProductGallery = async (id) => {
    try {
      let result = await api.delete(Apis.ProductGalleryDelete+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  
  const exportedObject = {
    createProduct,
    updateProduct,
    getAllProducts,
    getSingleProducts,
    createProductGallery,
    getProductGallery,
    deleteProductGallery
};
export default exportedObject;