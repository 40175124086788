import React, {useState, useEffect} from 'react'
import Breadcrumb from '../../../../components/partials/Breadcrumb';
import { BiCategory } from "react-icons/bi";
import { Alerts, GetPost } from '../../../../helpers';
import MUIDataTable from "mui-datatables";
import { Link } from  'react-router-dom';
import { FaEdit, FaTimes, FaPlus  } from "react-icons/fa";
import ConfirmationDialog from '../../../../components/cmp/ConfirmationDialog';
const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Categories",
    link: "/app/product/categories",
  },
];

const PostCategories = () => {
  const [isLoading, setLoading] = useState(true);
  const [arrData, setData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [delId, setDelId] = useState(null);
  const getData = async () =>{
     let resp = await GetPost.getAllPostCategories();
     if(resp && resp.status){
         setData(resp.data);
         setLoading(false);
     }
  }

  useEffect(()=>{
    getData();
  },[])
  
  const options = {
    filter: false,
    download:false,
    print:false,
    selectableRows: false
  };

  const columns = [
    {
      name: 'SL'
    },
    {
      name: 'Name',
      sortable: true,
    },
    {
      name: 'slug',
      sortable: true,
    },
    {
      name: 'Thumbnail',
    },
    {
      name: 'Status',
      sortable: true,
    },
    {
      name: 'Actions',
      sortable: false,
    },
  ];
  
const deleteRecord = async (id) =>{
   let resp = await GetPost.deletePostCategory(id); 
   if(resp && resp.status){
    Alerts.alertMessage(resp.message, 'top-right', 'success');
    getData();
   }else{
    Alerts.alertMessage(resp.message, 'top-right', 'error');
   }
   setDelId(null);
   setShowDialog(false);
}
 
const showDialogBox = (id) =>{
  setDelId(id);
  setShowDialog(true);
}
  return (
    <>
    <Breadcrumb breadcrumbs={breadcrumbs} />
       <div className="innerPageWrapper">
       <div className='container-fluid'>
        <div className='page-title'>
          <h2><BiCategory/> Post Categories</h2>
          <Link to="/app/post/categories/create" className="btn btn-success"><FaPlus/> Add New</Link>
        </div>
             {
              isLoading ? <div className='loader'>
                <img src='/images/loader.gif' alt='loader'/>
              </div> : <>
                 <MUIDataTable
                            title="Categories"
                            data={arrData.map((item, i) => {

                                return [
                                    ++i,
                                    item.title,
                                    item.slug,
                                    <img src={item.category_thumbnail.path} alt="thumbnail" width="80px"/>,
                                    <div className={`badge ${item.is_active ? "bg-success" : "bg-danger"}`}>{item.is_active ? 'Active' : 'Draft'}</div>,
                                    <div className={`actions`}>
                                      <Link to={`/app/post/categories/edit/${item.id}`} className="btn btn-info"><FaEdit size={14}/></Link> | <button onClick={()=>showDialogBox(item.id)} className="btn btn-danger"><FaTimes size={14}/></button>
                                    </div>
                                ]
                            })}
                            columns={columns}
                            options={options}
                        />
                      {showDialog ?  <ConfirmationDialog delId={delId} setShowDialog={setShowDialog} deleteRecord={deleteRecord} show={showDialog} message="Are you sure you want to delete this record."/> : null} 
              </>
             }
          </div>
      </div>
      </>
  )
}

export default PostCategories;