import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import MediaImageDropzone from "../../../components/cmp/MediaImageDropzone";
import { Formik } from "formik";
import { GetMedia, Alerts } from "../../../helpers";

const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Categories",
        link: "/app/Media Library",
    },
    {
        id: 3,
        label: "Create New",
        link: "/app/media-library/create",
    },
];



const CreateMedia = () => {
    const history = useHistory();
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    var formData = new FormData();
    
    formData.append('media_file', values.media_file);
    console.log(...formData);
    // let resp = await GetMedia.createMedia(formData);
    // if(resp && resp.status){
    //   Alerts.alertMessage(resp.message, "top-right", "success");
    //   history.push('/app/media-library');
    // }else{
    //   Alerts.alertMessage(resp.message, "top-right", "error");
    // }
    // resetForm();
    // setSubmitting(false);
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Media
                        </h2>
                        <Link to="/app/media-library" className="btn btn-success">
                            <BiCategory /> Media
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Formik
                                initialValues={{
                                    media_file: ''
                                }}
                                validate={(values) => {
                                    const errors = {};
                                    if (!values.media_file) {
                                        errors.media_file = "Media file is required";
                                    }



                                    return errors;
                                }}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue, // Add setFieldValue to handle file input
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <MediaImageDropzone setFieldValue={setFieldValue} />
                                            {errors.media_file && touched.media_file && (
                                                <div className="text-center">
                                                <span className="help-error ">
                                                    {errors.media_file}
                                                </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? "Uploading..." : "Upload File"}
                                            </button>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateMedia;
