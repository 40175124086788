import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { Container } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";
import { GetUser } from "../../../helpers";
import "../../../styles/layout.css";
import MUIDataTable from "mui-datatables";

const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Users",
    link: "/app/users",
  },
];



const options = {
  filter: false,
  download:false,
  print:false,
  selectableRows: false
};
const columns = ["#SL", "User ID", "Name", "Email", "Phone", "isActive"]


const User = () => {
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const fetchUser = async () => {
    let resp = await GetUser.getAllUser();

    if (resp) {
      setLoading(false);
      setUsers(resp);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);

  const columns = [
    {
      name: 'SL'
    },
    {
      name: 'Name',
      sortable: true,
    },
    {
      name: 'Email',
      sortable: true,
    },
    {
      name: 'Phone',
      sortable: true,
    },
    {
      name: 'Address',
      sortable: true,
    },
  ];
  

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <Container fluid="md">
          <h2>
            <FaUsers /> Users
          </h2>
          <br />
          {/* {
            users.length !== 0 &&    <DataTable
            columns={columns}
            data={users}
            pagination
          />
          } */}
 {isLoading ? "Data Loading..." : <MUIDataTable
                            title="Users List"
                            data={users.map((item, i) => {

                                return [
                                    ++i,
                                    item.name,
                                    item.email,
                                    item.mobile_number,
                                   `${item.address !== null ? item.address : ''} ${item.address_line_2 !== null ?`, ${item.address_line_2}` : ''} ${item.address_line_3 !== null ?`, ${item.address_line_3}` : ''}`,
                                    <div className={`status-pills ${item.is_active ? "pills-success" : "pills-close"}`}>{item.is_active ? 'Active' : 'Inactive'}</div>,
                                    <button className={`btn2 ${item.is_active ? 'danger-bg' : 'success-bg'}`} onClick={() => this.handleApprove(item.id)}>{item.is_active ? "Inactive" : 'Activate'}</button>,
                                    // <Link to={`/app/users/${item.id}`} className="btn-secondry action-btn">
                                    //     <SettingsIcon fontSize="small" />
                                    // </Link>


                                ]
                            })}
                            columns={columns}
                            options={options}
                        />
                        }
       
        </Container>
      </div>
    </>
  );
};

export default User;
