import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../../../components/partials/Breadcrumb';
import { BiCategory } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { GetProductCategory, Alerts } from '../../../../helpers';
import CKEditorField from '../../../../components/cmp/CKEditorField';
import MUIDataTable from "mui-datatables";
import ConfirmationDialog from '../../../../components/cmp/ConfirmationDialog';
import { FaEdit, FaTimes, FaPlus   } from "react-icons/fa";
import MyModal from '../../../../components/cmp/MyModal';
const breadcrumbs = [
  {
    id: 1,
    label: 'Dashboard',
    link: '/',
  },
  {
    id: 2,
    label: 'Categories',
    link: '/app/product/categories',
  },
  {
    id: 3,
    label: 'Create New',
    link: '/app/product/categories/faq',
  },
];
const options = {
  filter: false,
  download:false,
  print:false,
  selectableRows: false
};

const columns = [
  {
    name: 'SL'
  },
  {
    name: 'question',
    sortable: true,
  },
  {
    name: 'answer',
    sortable: true,
  },
  {
    name: 'Status',
    sortable: true,
  },
  {
    name: 'Actions',
    sortable: false,
  },
];
const CategoryFaq = () => {
  const {id} = useParams();
  const [isLoading, setLoading] = useState(true);
  const [category, setCategory] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [delId, setDelId] = useState(null);

  const [addNewFaq, setAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [singleFaq, setSingleFaq] = useState('');


  const handleEdit = async (id) =>{
    let fFaqs = category.faqs.find(el=>el.id === id);
    if(fFaqs){
      setSingleFaq(fFaqs);
      setIsEdit(true);
    }
  }

  const getFaqs = async (category_id) =>{
    let resp = await GetProductCategory.getCategoryFaqs(category_id);
    if(resp){
      if(resp.status){
        setCategory(resp.data);
        setLoading(false);
      }
    }
  }

  useEffect(()=>{
    getFaqs(id);
  },[id])

 
const handleSubmit = async (values, { setSubmitting, resetForm }) =>{
  let resp = await GetProductCategory.createCategoryFaq(values, id);
  if(resp){
    setSubmitting(false);
    if(resp.status){
      setAddNew(false);
      Alerts.alertMessage(resp.message, "top-right", "success");
      getFaqs(id);
      resetForm();
      
    }else{
      Alerts.alertMessage(resp.message, "top-right", "error");
    }
  }
}
const handleUpdateSubmit = async (values, { setSubmitting, resetForm }) =>{
  let resp = await GetProductCategory.updateCategoryFaq(values, singleFaq.id);
  if(resp){
    setSubmitting(false);
    if(resp.status){
      setIsEdit(false);
      Alerts.alertMessage(resp.message, "top-right", "success");
      getFaqs(id);
      resetForm();
      
    }else{
      Alerts.alertMessage(resp.message, "top-right", "error");
    }
  }
}

  
const deleteRecord = async (faq_id) =>{
  setLoading(true);
  let resp = await GetProductCategory.deleteCategoryFq(faq_id); 
  if(resp && resp.status){
   Alerts.alertMessage(resp.message, 'top-right', 'success');
   getFaqs(id);
  }else{
   Alerts.alertMessage(resp.message, 'top-right', 'error');
  }
  setDelId(null);
  setShowDialog(false);
}

const showDialogBox = (id) =>{
 setDelId(id);
 setShowDialog(true);
}
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <div className="container-fluid">
          <div className="page-title">
            <h2>
              <BiCategory /> Categories Faqs
            </h2>
            <button onClick={()=>setAddNew(true)} className="btn btn-success">
              <FaPlus /> Add New
            </button>
          </div>
          <div className="card">
            <div className="card-body">
          
              <br/>
            {
              isLoading ? <div className='loader'>
                <img src='/images/loader.gif' alt='loader'/>
              </div> : <>
                 <MUIDataTable
                            title="Categories Faqs"
                            data={category.faqs.map((item, i) => {

                                return [
                                    ++i,
                                    item.question,
                                    item.answer,
                                    <div className={`badge ${item.is_active ? "bg-success" : "bg-danger"}`}>{item.is_active ? 'Active' : 'Draft'}</div>,
                                    <div className={`actions`}>
                                      <button onClick={()=>handleEdit(item.id)} className="btn btn-info"><FaEdit size={14}/></button> | <button onClick={()=>showDialogBox(item.id)} className="btn btn-danger"><FaTimes size={14}/></button> 
                                    </div>
                                ]
                            })}
                            columns={columns}
                            options={options}
                        />
                      {showDialog ?  <ConfirmationDialog delId={delId} setShowDialog={setShowDialog} deleteRecord={deleteRecord} show={showDialog} message="Are you sure you want to delete this record."/> : null} 
              </>
             }

              <MyModal show={addNewFaq} setAddNew={setAddNew} title={'Create Faq'}>
              <Formik
                initialValues={{ question: '', answer: '' }}
                validate={(values) => {
                  const errors = {};
                  if (!values.question) {
                    errors.question = 'Question is required';
                  }
                  if (!values.answer) {
                    errors.answer = 'Answer is required';
                  }
                

                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
       
  
                        <div className="form-group">
                          <label>Question</label>
                          <input
                            type="text"
                            className="form-control"
                            name="question"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.question}
                          />
                          {errors.question && touched.question && <span className="help-error">{errors.question}</span>}
                        </div>
                        
                       
                            <div className="form-group">
                      <label>Answer</label>
                      <Field
                        name="answer"
                        render={({ field, form }) => (
                          <CKEditorField field={field} form={form} />
                        )}
                      />
                      {errors.answer && touched.answer && (
                        <span className="help-error">{errors.answer}</span>
                      )}
               
                       
                 
                    
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </form>
                )}
              </Formik>
              </MyModal>
            {
              isEdit ? singleFaq !== '' ?  <MyModal show={isEdit} setAddNew={setIsEdit} title={'Update Faq'}>
              <Formik
               enableReinitialize
                initialValues={{ question: singleFaq.question, answer: singleFaq.answer }}
                validate={(values) => {
                  const errors = {};
                  if (!values.question) {
                    errors.question = 'Question is required';
                  }
                  if (!values.answer) {
                    errors.answer = 'Answer is required';
                  }
                

                  return errors;
                }}
                onSubmit={handleUpdateSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
       
  
                        <div className="form-group">
                          <label>Question</label>
                          <input
                            type="text"
                            className="form-control"
                            name="question"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.question}
                          />
                          {errors.question && touched.question && <span className="help-error">{errors.question}</span>}
                        </div>
                        
                       
                            <div className="form-group">
                      <label>Answer</label>
                      <Field
                        name="answer"
                        render={({ field, form }) => (
                          <CKEditorField field={field} form={form} />
                        )}
                      />
                      {errors.answer && touched.answer && (
                        <span className="help-error">{errors.answer}</span>
                      )}
               
                       
                 
                    
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </form>
                )}
              </Formik>
              </MyModal>: null : null
            }
           

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryFaq;
