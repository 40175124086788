const API_URL = 'https://apitriplast.articloo.com'
// const API_URL = 'http://localhost:3001'

const Apis = {
    UserLogin:`${API_URL}/api/auth/sign-in`,
    GetUserProfile:`${API_URL}/api/user/profile`,
    UpdateUserProfile:`${API_URL}/api/user/profile/update`,
    CheckUserCurrentPassword:`${API_URL}/api/user/check/password`,
    UpdateUserPassword:`${API_URL}/api/user/update/password`,
    GetAllUsers:`${API_URL}/api/user/all`,
    //Product Categories
    GetAllCategories:`${API_URL}/api/categories`,
    CreateCategory:`${API_URL}/api/category/create`,
    DeleteCategory:`${API_URL}/api/category/`,
    SingleCategory:`${API_URL}/api/category/`,
    UpdateCategory:`${API_URL}/api/category/`,
    GetCategoryFaqs:`${API_URL}/api/category/faq/`,
    CreateCategoryFaq:`${API_URL}/api/category/faq/create/`,
    DeleteCategoryFaq:`${API_URL}/api/category/faq/`,  
    UpdateCategoryFaq:`${API_URL}/api/category/faq/update/`,   
    CreateProduct:`${API_URL}/api/product/create`, 
    UpdateProduct:`${API_URL}/api/product/edit/`,  
    GetAllProducts:`${API_URL}/api/products`, 
    GetSingleProduct:`${API_URL}/api/products/`,   
    CreateProductGallery:`${API_URL}/api/product/gallery/`,  
    GetProductGallery:`${API_URL}/api/product/gallery/`,  
    ProductGalleryDelete:`${API_URL}/api/product/gallery/`,  
    
    //Media Routes
    CreateMedia:`${API_URL}/api/media/create`, 
    GetAllMedia:`${API_URL}/api/media/all`, 
    DeleteMedia:`${API_URL}/api/media/`,  

    //Post Categories
    GetAllPostCategories:`${API_URL}/api/post/categories`,
    CreatePostCategory:`${API_URL}/api/post/category/create`,
    DeletePostCategory:`${API_URL}/api/post/category/`,
    SinglePostCategory:`${API_URL}/api/post/category/`,
    UpdatePostCategory:`${API_URL}/api/post/category/`,
    CreatePost:`${API_URL}/api/post/create`,
    GetAllPost:`${API_URL}/api/posts`,
    GetSinglePost:`${API_URL}/api/post/`,
    DeletePost:`${API_URL}/api/post/`,
    UpdatePost:`${API_URL}/api/post/`,
    UploadCkeditor:`${API_URL}/api/ckeditor/upload`,

  };
export { API_URL, Apis };