import React,{useState} from 'react'
import '../../styles/layout.css'
import { Switch, Route, Link } from "react-router-dom";
import Sidebar from '../partials/Sidebar';
import '../../styles/layout.css'
import { FaEnvelope, FaBell, FaUser, FaUnlockAlt } from 'react-icons/fa';
import { FiMenu, FiChevronLeft } from 'react-icons/fi';
import Logout from '../Logout';

//pages
import Dashboard from '../../pages/Dashboard/Dashboard';
import Profile from '../../pages/Dashboard/Users/profile/profile';
import ChangePassword from '../../pages/Dashboard/Users/password/ChangePassword';
import User from '../../pages/Dashboard/Users/User';
import CreateCategory from '../../pages/Dashboard/Products/Category/CreateCategory';
import Categories from '../../pages/Dashboard/Products/Category/Categories';
import EditCategory from '../../pages/Dashboard/Products/Category/EditCategory';
import CategoryFaq from '../../pages/Dashboard/Products/Category/CategoryFaq';
//Post Pages
import CreatePostCategory from '../../pages/Dashboard/Post/Category/CreatePostCategory';
import PostCategories from '../../pages/Dashboard/Post/Category/PostCategories';
import EditPostCategory from '../../pages/Dashboard/Post/Category/EditPostCategory';
import Post from '../../pages/Dashboard/Post/Post';
import CreatePost from '../../pages/Dashboard/Post/CreatePost';
import EditPost from '../../pages/Dashboard/Post/EditPost';
import CreateProduct from '../../pages/Dashboard/Products/CreateProduct';
import Products from '../../pages/Dashboard/Products/Products';
import EditProduct from '../../pages/Dashboard/Products/EditProduct';
import MediaLibrary from '../../pages/Dashboard/MediaLibrary/MediaLibrary';
import CreateMedia from '../../pages/Dashboard/MediaLibrary/CreateMedia';
import ProductSlider from '../../pages/Dashboard/Products/ProductSlider';
const Layout = () => {
    const [toggleSidebar, setToggleSidebar] = useState(false)
    return (
        <div className='layoutWrapper'>
           <Sidebar toggleSidebar={toggleSidebar}/>
            <div className={`layoutMain ${!toggleSidebar ? 'ml-256' : ''}`}>
                <header className='topHeader'>
                    <div className={`${toggleSidebar ? 'sidebarBtn ml-15' : 'sidebarBtn'}`}>
                        <button  onClick={()=>setToggleSidebar(!toggleSidebar)}>
                          {
                            toggleSidebar ? <FiMenu/> : <FiChevronLeft/>
                          } 
                        </button>
                    </div>
                    <ul className='topHeaderMenu'>
                        <li>
                            <Link to="#" className='ml-15'><FaBell /></Link>
                        </li>
                        <li>
                            <Link to="#"><FaEnvelope /></Link>
                        </li>

                        <li className="profile-menu">
                            <div className="c-avatar"><img className="c-avatar-img" src={'/images/avatar.jfif'} alt="user@email.com" /></div>
                            <ul className="profile-link-dropdown">
                                <li> <Link to="/app/profile"><FaUser/> Profile</Link></li>
                                <li> <Link to="/app/update-password"><FaUnlockAlt/> Update Password</Link></li>
                                <li> <Logout/></li>
                            </ul>
                        </li>

                    </ul>
                </header>
                <main className='appMain'>
                    <Switch>
                        <Route path="/app/dashboard" exact component={Dashboard} />
                        <Route path="/app/profile" exact component={Profile} />
                        <Route path="/app/update-password" exact component={ChangePassword} />
                        <Route path="/app/users" exact component={User} />
                        <Route path="/app/product/categories" exact component={Categories} />
                        <Route path="/app/product/categories/create" exact component={CreateCategory} />
                        <Route path="/app/product/categories/edit/:id" exact component={EditCategory} />
                        <Route path="/app/product/categories/faq/:id" exact component={CategoryFaq} />
                        <Route path="/app/product/create" exact component={CreateProduct} />
                        <Route path="/app/products" exact component={Products} />
                        <Route path="/app/product/edit/:id" exact component={EditProduct} />
                        <Route path="/app/product/media/:product_id" exact component={ProductSlider} />
                        
                        <Route path="/app/media-library" exact component={MediaLibrary} />
                        <Route path="/app/media-library/create" exact component={CreateMedia} />
                        
                        

                        <Route path="/app/post/categories" exact component={PostCategories} />
                        <Route path="/app/post/categories/create" exact component={CreatePostCategory} />
                        <Route path="/app/post/categories/edit/:id" exact component={EditPostCategory} />
                        <Route path="/app/posts" exact component={Post} />
                        <Route path="/app/posts/create" exact component={CreatePost} />
                        <Route path="/app/posts/edit/:id" exact component={EditPost} />
                        
                        
                        
                        
                        
                    </Switch>
                </main>
                <footer>
                    <p>Triplast ecommerce © 2022. All Rights Reserved</p>
                </footer>
            </div>

        </div>
    )
}

export default Layout