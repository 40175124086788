import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = sessionStorage.getItem('userToken');
const getAllCategories = async () => {
  try {
    let result = await api.get(Apis.GetAllCategories, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const createCategory = async (data) => {
  try {
    let result = await api.post(Apis.CreateCategory, data, {
        headers: {
          'x-access-token': token,
          // "Content-Type": "multipart/form-data",
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const updateCategory = async (data, id) => {
  try {
    let result = await api.put(Apis.UpdateCategory+id, data, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const deleteCategory = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteCategory+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const getSingleCategory = async (id) => {
  try {
    let result = await api.get(Apis.SingleCategory+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const getCategoryFaqs = async (id) => {
  try {
    let result = await api.get(Apis.GetCategoryFaqs+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const createCategoryFaq = async (data, id) => {
  try {
    let result = await api.post(Apis.CreateCategoryFaq+id, data, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const deleteCategoryFq = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteCategoryFaq+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const updateCategoryFaq = async (data, id) => {
  try {
    let result = await api.put(Apis.UpdateCategoryFaq+id, data, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const exportedObject = {
    getAllCategories,
    createCategory,
    deleteCategory,
    getSingleCategory,
    updateCategory,
    getCategoryFaqs,
    createCategoryFaq,
    deleteCategoryFq,
    updateCategoryFaq
};
export default exportedObject;